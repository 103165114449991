import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, map, mergeAll, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MessageComponent } from '../components/modal/message/message.component';
import { ModalService } from '../components/modal/modal.service';
import { ModalRef } from '../components/modal/modalRef';
import { UploadProgressComponent } from '../components/modal/upload-progress/upload-progress.component';
import { ParseEntity } from '../helpers/utilities';
import { CustomError } from '../interfaces/error';
import { Asset } from '../models/Asset';
import { Message, MessageTitle } from '../models/Message';
import { DataleanDataProviderService } from '../provider/datalean-data-provider.service';
import { CommunitiesService } from './communities.service';
import { IconResolverService } from './icon-resolver.service';
import { MediaLibraryService } from './media-library.service';
import { UploadService } from './upload.service';

@Injectable({
  providedIn: 'root',
})
export class UploadManagerService {
  files: any[] = [];
  communityUUID = undefined;

  modalRef: ModalRef | undefined;

  constructor(
    public mediaLibraryService: MediaLibraryService,
    public uploadService: UploadService,
    private dataleanDataService: DataleanDataProviderService,
    private communitiesService: CommunitiesService,
    private iconResolverService: IconResolverService,
    private modalService: ModalService,
  ) {
    this.communityUUID = this.communitiesService.getLastCommunitySelected();
  }

  get Files() {
    return this.files.length > 0 ? this.files : null;
  }

  saveAssetAndUploadFile(assetData, file: File, newAsset, showPopup = true): Observable<any> {
    if (showPopup) {
      this.modalRef = this.modalService.open({
        component: UploadProgressComponent,
        position: new GlobalPositionStrategy().centerHorizontally().centerVertically(),
        data: {},
      });

      this.modalRef.onDidClose.subscribe((event) => {
        if (event.data === 'background') {
          // console.log('uploadProgressComponent modal closed');
        }
      });
    }
    const formData = new FormData();
    formData.append('file', file);
    if (!newAsset) {
      delete assetData._id;
    }

    const parsedAsset = ParseEntity(new Asset(), assetData);
    formData.append('assetData', JSON.stringify(parsedAsset));

    return this.saveAsset(
      formData,
      {
        observe: 'events',
        reportProgress: true,
        responseType: 'json',
      },
      newAsset,
      !newAsset ? assetData.uuid : undefined,
    ).pipe(
      map((event) => {
        if (event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress) {
          this.uploadService.progress = Math.floor((event.loaded * 100) / event.total);
          this.uploadService.totalSize = event.total;
          this.uploadService.partialSize = event.loaded;

          if (this.files?.length) {
            this.files.find((fileToUpload) => file.name === fileToUpload.name)['progress'] = this.uploadService.progress;
          }
        }
        if (event.type === HttpEventType.Response) {
          this.modalRef?.close();

          return { body: event.body, type: event.type };
        }
      }),
      filter((event) => event !== undefined && event.type === HttpEventType.Response),
      map((event) => event.body),
      catchError((error) => {
        this.modalRef?.close();
        if (error instanceof HttpErrorResponse) {
          if (error.error.Errors[0]?.message.includes('E11000') || error.error.Errors[0].message.includes('MEDIALIBRARY.NAME_TAKEN')) {
            this.openMessageModal(new Message(MessageTitle.ERROR, 'MODALS.MEDIA_LIBRARY.ADD_ITEM.FILE_ALREADY_EXISTS'));
          }
        }
        return throwError(error);
      }),
    );
  }

  saveAsset(formData: FormData, options: any, newAsset: boolean, assetUUID?: string): Observable<any> {
    if (newAsset) {
      return this.dataleanDataService.createEntity(environment.mediaLibraryUrl, formData, [], { checkPath: true }, true, options);
    } else {
      return this.dataleanDataService.updateEntityWithFormData(
        environment.mediaLibraryUrl,
        formData,
        assetUUID,
        [],
        undefined,
        { checkPath: true },
        true,
        options,
      );
    }
  }

  prepareFilesList(files) {
    const saveSubject = new Subject();
    Object.keys(files).forEach((key) => {
      files[key]['preview'] = this.iconResolverService.getIcon(files[key].type);
      files[key]['progress'] = 0;
      const reader = new FileReader();
      reader.readAsDataURL(files[key]);
      reader.onload = (event) => {
        if (event.target.result.toString().includes('image')) {
          files[key]['preview'] = reader.result;
        } else {
          files[key]['preview'] = this.iconResolverService.getIcon(files[key].type);
        }
      };
    });
    this.files = [...files];

    this.paginatedCalls(this.files).subscribe(() => {
      saveSubject.next(true);
    });

    return saveSubject.asObservable();
  }

  paginatedCalls(files) {
    const doneSubject = new Subject();
    //const newProductList = [];
    this.communityUUID = this.communitiesService.getDataFromLastCommunitySelected()?.uuid;
    Object.keys(files).forEach((key) => {
      files[key]['preview'] = this.iconResolverService.getIcon(files[key].type);
      files[key]['progress'] = 0;
      const reader = new FileReader();
      reader.readAsDataURL(files[key]);
      reader.onload = (event) => {
        if (event.target.result.toString().includes('image')) {
          files[key]['preview'] = reader.result;
        } else {
          files[key]['preview'] = this.iconResolverService.getIcon(files[key].type);
        }
      };
    });
    this.files = [...files];

    const fileCount = files.length;
    const calls = new Subject<Observable<any>>();
    let sameNameCount = 0;
    calls
      .pipe(
        mergeAll(1),
        take(fileCount),
        catchError((err: CustomError) => {
          if (err?.error?.Errors?.some((er) => er.detail?.includes('E11000'))) sameNameCount++;
          return of();
        }),
        tap({
          complete: () => {
            if (sameNameCount > 0) {
              this.openMessageModal(new Message('MESSAGE.TITLE.ERROR', 'MODALS.MEDIA_LIBRARY.ADD_ITEM.MULTIPLE_FILE_ALREADY_EXISTS'));
            }
            doneSubject.next(true);
          },
        }),
      )
      .subscribe();

    for (let i = 0; i < fileCount; i++) {
      calls.next(
        new Observable((observer) => {
          const assetData = { name: files[i].name };
          if (this.mediaLibraryService.getParentUUID()) {
            assetData['parentUUID'] = this.mediaLibraryService.getParentUUID();
          }
          if (this.communityUUID) assetData['communityUUID'] = this.communityUUID;
          this.saveAssetAndUploadFile(assetData, files[i], true, false).subscribe(
            (data) => {
              if (data) {
                files[i]['success'] = true;
                observer.next();
                observer.complete();
              }
            },
            (error) => {
              if (this.files?.length) {
                files[i]['failure'] = true;
              }
              observer.error(error);
              observer.complete();
            },
          );
        }),
      );
    }
    return doneSubject;
  }

  openMessageModal(message: Message) {
    return this.modalService.open({
      component: MessageComponent,
      class: 'message-modal',
      position: new GlobalPositionStrategy().centerHorizontally().top(),
      data: {
        message: message.message,
        status: message.status,
        code: message.code,
        title: message.title,
      },
    });
  }
}
