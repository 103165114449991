import { PermissionCategory } from './PermissionCategory.enum';

export const Permission = {
  /* Navigazione Principale */
  READ_CONFIGURATION: { name: 'READ_CONFIGURATION', category: PermissionCategory.MAIN_NAVIGATION },
  READ_PRODUCTS: { name: 'READ_PRODUCTS', category: PermissionCategory.MAIN_NAVIGATION },
  READ_COMMUNICATIONS: { name: 'READ_COMMUNICATIONS', category: PermissionCategory.MAIN_NAVIGATION },
  READ_MEDIA_LIBRARY: { name: 'READ_MEDIA_LIBRARY', category: PermissionCategory.MAIN_NAVIGATION },
  READ_DASHBOARD: { name: 'READ_DASHBOARD', category: PermissionCategory.MAIN_NAVIGATION },
  READ_CONTENTS: { name: 'READ_CONTENTS', category: PermissionCategory.MAIN_NAVIGATION },
  READ_CONTESTS: { name: 'READ_CONTESTS', category: PermissionCategory.MAIN_NAVIGATION },
  READ_INTEGRATIONS: { name: 'READ_INTEGRATIONS', category: PermissionCategory.MAIN_NAVIGATION },
  READ_ORDERS: { name: 'READ_ORDERS', category: PermissionCategory.MAIN_NAVIGATION },
  READ_DATALEAN_USERS: { name: 'READ_DATALEAN_USERS', category: PermissionCategory.MAIN_NAVIGATION },
  READ_APPLICATION_USERS: { name: 'READ_APPLICATION_USERS', category: PermissionCategory.MAIN_NAVIGATION },
  READ_TABLES: { name: 'READ_TABLES', category: PermissionCategory.MAIN_NAVIGATION },
  READ_APPLICATION_GROUPS: { name: 'READ_APPLICATION_GROUPS', category: PermissionCategory.MAIN_NAVIGATION },
  READ_SURVEY: { name: 'READ_SURVEY', category: PermissionCategory.MAIN_NAVIGATION },
  READ_STRUCTURE: { name: 'READ_STRUCTURE', category: PermissionCategory.MAIN_NAVIGATION },
  READ_FEATURES: { name: 'READ_FEATURES', category: PermissionCategory.MAIN_NAVIGATION },
  READ_BOOKABLE_RESOURCES: { name: 'READ_BOOKABLE_RESOURCES', category: PermissionCategory.MAIN_NAVIGATION },
  READ_LMS: { name: 'READ_LMS', category: PermissionCategory.MAIN_NAVIGATION },
  READ_RESERVATIONS: { name: 'READ_RESERVATIONS', category: PermissionCategory.MAIN_NAVIGATION },
  READ_ROLES: { name: 'READ_ROLES', category: PermissionCategory.MAIN_NAVIGATION },
  READ_PRODUCT_QUESTION: { name: 'READ_PRODUCT_QUESTION', category: PermissionCategory.MAIN_NAVIGATION },
  READ_FILTERS: { name: 'READ_FILTERS', category: PermissionCategory.MAIN_NAVIGATION },
  READ_RESOURCES: { name: 'READ_RESOURCES', category: PermissionCategory.MAIN_NAVIGATION },
  READ_BOOKABLE_PRODUCTS: { name: 'READ_BOOKABLE_PRODUCTS', category: PermissionCategory.MAIN_NAVIGATION },
  READ_LEARNING_MANAGEMENT_SYSTEM: { name: 'READ_LEARNING_MANAGEMENT_SYSTEM', category: PermissionCategory.MAIN_NAVIGATION },
  READ_SETTINGS: { name: 'READ_SETTINGS', category: PermissionCategory.MAIN_NAVIGATION },

  /*-----------------------*/

  /* COMMUNITIES */
  VIEW_COMMUNITIES: { name: 'VIEW_COMMUNITIES', category: PermissionCategory.COMMUNITIES },
  VIEW_ALL_COMMUNITIES: { name: 'VIEW_ALL_COMMUNITIES', category: PermissionCategory.COMMUNITIES },
  CREATE_COMMUNITIES: { name: 'CREATE_COMMUNITIES', category: PermissionCategory.COMMUNITIES },
  EDIT_COMMUNITIES: { name: 'EDIT_COMMUNITIES', category: PermissionCategory.COMMUNITIES },
  CLONE_INTO_COMMUNITY: { name: 'CLONE_INTO_COMMUNITY', category: PermissionCategory.COMMUNITIES },
  ASSIGN_COMMUNITY_ADMIN: { name: 'ASSIGN_COMMUNITY_ADMIN', category: PermissionCategory.COMMUNITIES },
  ASSOCIATE_TO_COMMUNITY: { name: 'ASSOCIATE_TO_COMMUNITY', category: PermissionCategory.COMMUNITIES },
  ADMINISTER_COMMUNITY: { name: 'ADMINISTER_COMMUNITY', category: PermissionCategory.COMMUNITIES },
  MANAGE_DELIVERY_TYPE: { name: 'MANAGE_DELIVERY_TYPE', category: PermissionCategory.COMMUNITIES },
  /*-----------------------*/

  /* CONFIGURATIONS */

  EDIT_CONFIGURATION_TAB: { name: 'EDIT_CONFIGURATION_TAB', category: PermissionCategory.CONFIGURATIONS },
  CREATE_CONFIGURATION: { name: 'CREATE_CONFIGURATION', category: PermissionCategory.CONFIGURATIONS },
  DELETE_CONFIGURATION: { name: 'DELETE_CONFIGURATION', category: PermissionCategory.CONFIGURATIONS },

  /* OTHERS */
  ANY: { name: '*', category: PermissionCategory.OTHERS },
  /*-----------------------*/

  /* MEDIA_LIBRARY */
  MANAGE_PUBLIC_ASSETS: { name: 'MANAGE_PUBLIC_ASSETS', category: PermissionCategory.MEDIA_LIBRARY },

  /*-----------------------*/

  /* FEATURES */
  CREATE_FEATURES: { name: 'CREATE_FEATURES', category: PermissionCategory.FEATURES },
  EDIT_FEATURES: { name: 'EDIT_FEATURES', category: PermissionCategory.FEATURES },
  EDIT_ADMIN_FEATURES: { name: 'EDIT_ADMIN_FEATURES', category: PermissionCategory.FEATURES },
  REMOVE_ADMIN_FEATURES: { name: 'REMOVE_ADMIN_FEATURES', category: PermissionCategory.FEATURES },
  CLONE_FEATURES: { name: 'CLONE_FEATURES', category: PermissionCategory.FEATURES },
  CREATE_FEATURE_VALUES: { name: 'CREATE_FEATURE_VALUES', category: PermissionCategory.FEATURES },
  ADD_ICON_FEATURES: { name: 'ADD_ICON_FEATURES', category: PermissionCategory.FEATURES },
  FILTER_OPTIONS: { name: 'FILTER_OPTIONS', category: PermissionCategory.FEATURES },

  /* USERS */
  ASSIGN_ADMIN_ROLE: { name: 'ASSIGN_ADMIN_ROLE', category: PermissionCategory.USERS },
  /*-----------------------*/

  /* STRUCTURES */
  CAN_DEFINE_STRUCTURE_FIELD_PERMISSION: { name: 'CAN_DEFINE_STRUCTURE_FIELD_PERMISSION', category: PermissionCategory.STRUCTURES },
  /*-----------------------*/

  /* APP-USERS */
  IMPORT_APPLICATION_USERS: { name: 'IMPORT_SMARTCOMMUNITY_USERS', category: PermissionCategory.APP_USERS },
  EXPORT_APPLICATION_USERS: { name: 'EXPORT_APPLICATION_USERS', category: PermissionCategory.APP_USERS },
  ADD_APPLICATION_USERS: { name: 'ADD_APPLICATION_USERS', category: PermissionCategory.APP_USERS },
  EDIT_APPLICATION_USERS: { name: 'EDIT_APPLICATION_USERS', category: PermissionCategory.APP_USERS },
  EDIT_APPLICATION_USER_RESPONSIBLE: { name: 'EDIT_APPLICATION_USER_RESPONSIBLE', category: PermissionCategory.APP_USERS },
  /*-----------------------*/

  /* PRODUCTS */
  IMPORT_PRODUCTS: { name: 'IMPORT_PRODUCTS', category: PermissionCategory.PRODUCTS },
  IMPORT_RELATED_PRODUCTS: { name: 'IMPORT_RELATED_PRODUCTS', category: PermissionCategory.PRODUCTS },
  EXPORT_PRODUCTS: { name: 'EXPORT_PRODUCTS', category: PermissionCategory.PRODUCTS },
  EXPORT_XML_PRODUCTS: { name: 'EXPORT_XML_PRODUCTS', category: PermissionCategory.PRODUCTS },
  CREATE_TEMPLATE_PRODUCT: { name: 'CREATE_TEMPLATE_PRODUCT', category: PermissionCategory.PRODUCTS },
  VIEW_TEMPLATE_PRODUCT: { name: 'VIEW_TEMPLATE_PRODUCT', category: PermissionCategory.PRODUCTS },
  READ_CUSTOMIZATIONS: { name: 'READ_CUSTOMIZATIONS', category: PermissionCategory.PRODUCTS },
  READ_WISHLISTS: { name: 'READ_WISHLISTS', category: PermissionCategory.PRODUCTS },
  EXPORT_DATASHEET: { name: 'EXPORT_DATASHEET', category: PermissionCategory.PRODUCTS },
  EXPORT_ECP_PRODUCTS: { name: 'EXPORT_ECP_PRODUCTS', category: PermissionCategory.PRODUCTS },
  EXPORT_CATALOGUE_PRODUCTS: { name: 'EXPORT_CATALOGUE_PRODUCTS', category: PermissionCategory.PRODUCTS },
  /*-----------------------*/

  /* CONTENTS */
  IMPORT_CONTENTS: { name: 'IMPORT_CONTENTS', category: PermissionCategory.CONTENTS },
  IMPORT_RELATED_CONTENTS: { name: 'IMPORT_RELATED_CONTENTS', category: PermissionCategory.CONTENTS },
  EXPORT_CONTENTS: { name: 'EXPORT_CONTENTS', category: PermissionCategory.CONTENTS },
  EXPORT_XML_CONTENTS: { name: 'EXPORT_XML_CONTENTS', category: PermissionCategory.CONTENTS },
  CREATE_TEMPLATE_CONTENT: { name: 'CREATE_TEMPLATE_CONTENT', category: PermissionCategory.CONTENTS },
  VIEW_TEMPLATE_CONTENT: { name: 'VIEW_TEMPLATE_CONTENT', category: PermissionCategory.CONTENTS },
  /*-----------------------*/

  /* COMMUNICATIONS */
  IMPORT_COMMUNICATIONS: { name: 'IMPORT_COMMUNICATIONS', category: PermissionCategory.COMMUNICATIONS },
  EXPORT_COMMUNICATIONS: { name: 'EXPORT_COMMUNICATIONS', category: PermissionCategory.COMMUNICATIONS },
  READ_COCKPIT_COMMUNICATIONS: { name: 'READ_COCKPIT_COMMUNICATIONS', category: PermissionCategory.COMMUNICATIONS },
  /*-----------------------*/

  /* GROUPS */
  IMPORT_APPGROUP: { name: 'IMPORT_APPGROUP', category: PermissionCategory.GROUPS },
  CAN_VIEW_ADMIN_SMART_GROUP: { name: 'CAN_VIEW_ADMIN_SMART_GROUP', category: PermissionCategory.GROUPS },
  /*-----------------------*/

  /* SURVEYS */
  EXPORT_SURVEY: { name: 'EXPORT_SURVEY', category: PermissionCategory.SURVEYS },
  /*-----------------------*/

  /* ORDERS */
  EDIT_ORDER_STATUS: { name: 'EDIT_ORDER_STATUS', category: PermissionCategory.ORDERS },
  /*-----------------------*/

  /* INTEGRATIONS */
  EDIT_INTEGRATIONS: { name: 'EDIT_INTEGRATIONS', category: PermissionCategory.INTEGRATIONS },

  /*TO REMOVE */
  COMMUNITY_ADMIN: { name: 'COMMUNITY_ADMIN', category: PermissionCategory.TO_REMOVE },
  GROUP_ADMIN: { name: 'GROUP_ADMIN', category: PermissionCategory.TO_REMOVE },
  /*-----------------------*/

  /* GIFT CARD */

  CREATE_GIFT_CARD: { name: 'CREATE_GIFT_CARD', category: PermissionCategory.GIFT_CARD },
  MANAGE_GIFT_CARD: { name: 'MANAGE_GIFT_CARD', category: PermissionCategory.GIFT_CARD },
  CAN_DISABLE_GIFT_CARD: { name: 'CAN_DISABLE_GIFT_CARD', category: PermissionCategory.GIFT_CARD },
  CAN_TOPUP_GIFT_CARD: { name: 'CAN_TOPUP_GIFT_CARD', category: PermissionCategory.GIFT_CARD },
};
